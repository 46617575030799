import React from "react"
import { Helmet } from "react-helmet"

import Nav from "../partials/Nav"
import PageSection from "../layout/PageSection"
import Hero from "../partials/Hero"
import AboutRfh from "../partials/AboutRfh"
import AsSeenIn from "../partials/AsSeenIn"
import OurPartners from "../partials/OurPartners"
import OurCommunityStories from "../partials/OurCommunityStories"
import Footer from "../partials/Footer"
import CookieBanner from "../partials/CookieBanner"
import RaisedSoFar from "../partials/RaisedSoFar"
import OurNextEvent from "../partials/OurNextEvent"
import Video from "../partials/Video"
import "../styles.css"

const Home: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RUN FOR HEROES | Creating positive change through running</title>
        <meta
          name="description"
          content="We’re on a mission to get as many people active as possible while raising vital funds for charity. Sign up to our next event to get fit and give back."
        />
        <html lang="en" />
      </Helmet>
      <Nav activePath={"/"} />
      <PageSection backgroundColor={"BLUE"} noPadding>
        <Hero />
      </PageSection>
      {/* <PageSection backgroundColor={"MUTED_GREY"}>
        <RaisedSoFar />
      </PageSection> */}
      {/* <PageSection backgroundColor={"MUTED_GREY"}>
        <OurNextEvent />
      </PageSection> */}
      <PageSection backgroundColor={"MUTED_GREY"}>
        <Video />
      </PageSection>
      <PageSection backgroundColor={"PINK"}>
        <OurCommunityStories />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <AboutRfh />
      </PageSection>
      <PageSection backgroundColor={"BLUE"}>
        <OurPartners />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <AsSeenIn />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </React.Fragment>
  )
}

export default Home
