import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import Button from "../components/Button"
import BodyText from "../components/BodyText"
import { H2 } from "../components/Heading"
import { LAYOUT, FONT_FAMILIES } from "../constants"

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  padding: 30px 0 20px 0;
  margin: 0 auto;
  flex-direction: column;
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    padding-bottom: 0;
    padding: 60px 0 60px 0;
  }
`

const StyledBodyText = styled(BodyText)`
  margin-bottom: 20px;
  text-align: center;
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    text-align: left;
  }
`
const MainContentContainer = styled.div`
  width: 100%;
  max-width: 906px;
  margin: 0 auto;
  flex-direction: column;
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    flex-direction: row;
    display: flex;

    justify-content: space-between;
    margin-bottom: 40px;
    align-items: center;
  }
`

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    padding-left: 54px;
    max-width: 470px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const StyledButton = styled(Button)`
  display: inline-flex;
  margin: 0 5px;
  justify-content: center;
  margin-bottom: 20px;
  width: 200px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    width: auto;
    margin-bottom: 0;
    margin-right: 5px;
  }
`

const Heading = styled(H2)`
  margin-bottom: 12px;
  text-align: center;
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    text-align: left;
  }
`

interface IOurStoryImage {
  fluid: object
}

const OurStoryImage = styled(Img)<IOurStoryImage>`
  align-self: center;
  justify-self: center;
  width: 100%;
  max-width: 250px;
  margin: 30px auto 0 auto;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    max-width: 426px;

    flex-shrink: 0;
    margin-top: 0;
  }
`

interface IOurStory {
  rest?: object
}

const AboutRfh: React.FC<IOurStory> = ({ ...rest }) => {
  const data = useStaticQuery(graphql`
    query AboutRfhQuery {
      ourStory: file(relativePath: { eq: "ourStory/photos.png" }) {
        childImageSharp {
          fluid(maxWidth: 426, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Root {...rest}>
      <MainContentContainer>
        <OurStoryImage
          fluid={data.ourStory.childImageSharp.fluid}
          alt={"Our story"}
          loading={"eager"}
          fadeIn={false}
        />
        <CopyContainer>
          <Heading>About Run For Heroes</Heading>
          <StyledBodyText type={"DARK"}>
            <p>
              In 2020 we captured the hearts of millions of people across the
              country asking them to run 5k. We mobilised them to get active and
              give back to society raising over £7 million for NHS Charities
              Covid-19 Appeal.
            </p>
            <p>
              On our journey we learnt the power of communities and how lots of
              individuals doing something small can create huge positive change.
            </p>
          </StyledBodyText>
          <ButtonContainer>
            <StyledButton color={"WHITE_BORDERED"} to={"/about-us"}>
              Learn more
            </StyledButton>
            <StyledButton color={"GREY"} to={"/about-us/our-charity"}>
              Get involved
            </StyledButton>
          </ButtonContainer>
        </CopyContainer>
      </MainContentContainer>
    </Root>
  )
}

export default AboutRfh
