import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { H2 } from "../components/Heading"
import { LAYOUT } from "../constants"

const IMG_DATA = [
  { title: "The Guardian", key: "guardian" },
  { title: "Evening Standard", key: "eveningStandard" },
  { title: "The Times", key: "theTimes" },
  { title: "BBC News", key: "bbcNews" },
  { title: "Sky News", key: "skyNews" },
  { title: "Runners World", key: "runnersWorld" },
  { title: "Cosmo", key: "cosmo" },
  { title: "ITV News", key: "itvNews" },
  { title: "Marie Claire", key: "marieClaire" },
  { title: "The Scotsman", key: "theScotsman" },
  { title: "Independent", key: "independent" },
  { title: "Daily Mail", key: "dailyMail" },
  { title: "stv", key: "stv" },
  { title: "The Telegraph", key: "theTelegraph" },
  { title: "Reuters", key: "reuters" },
  { title: "People", key: "people" },
  { title: "Channel 5", key: "channel5" },
  { title: "Channel 4", key: "channel4" },
  { title: "The tab", key: "theTab" },
]

const Root = styled.div`
  max-width: 906px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`
const LogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

interface ILogo {
  fluid: Object
}

const Logo = styled(Img)<ILogo>`
  width: 100px;
  align-self: center;

  margin: 0 10px 20px 10px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    width: 202px;
  }
`

const Heading = styled(H2)`
  margin-bottom: 30px;
`

interface IAsSeenIn {
  rest?: object
}

const AsSeenIn: React.FC<IAsSeenIn> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query AsSeenInQuery {
      guardian: file(relativePath: { eq: "asSeenIn/the-guardian.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      eveningStandard: file(
        relativePath: { eq: "asSeenIn/evening-standard.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      theTimes: file(relativePath: { eq: "asSeenIn/the-times.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      bbcNews: file(relativePath: { eq: "asSeenIn/bbc-news.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      skyNews: file(relativePath: { eq: "asSeenIn/sky-news.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      runnersWorld: file(relativePath: { eq: "asSeenIn/runners-world.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      cosmo: file(relativePath: { eq: "asSeenIn/cosmo.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      itvNews: file(relativePath: { eq: "asSeenIn/itv-news.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      marieClaire: file(relativePath: { eq: "asSeenIn/marie-claire.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      theScotsman: file(relativePath: { eq: "asSeenIn/the-scotsman.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      independent: file(relativePath: { eq: "asSeenIn/independent.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      dailyMail: file(relativePath: { eq: "asSeenIn/daily-mail.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      stv: file(relativePath: { eq: "asSeenIn/stv.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      theTelegraph: file(relativePath: { eq: "asSeenIn/the-telegraph.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      reuters: file(relativePath: { eq: "asSeenIn/reuters.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      skyNews: file(relativePath: { eq: "asSeenIn/sky-news.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      people: file(relativePath: { eq: "asSeenIn/people.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      channel4: file(relativePath: { eq: "asSeenIn/channel-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      channel5: file(relativePath: { eq: "asSeenIn/channel-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      theTab: file(relativePath: { eq: "asSeenIn/the-tab.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <Heading color={"GREY"} alignment={"CENTER"}>
        As seen in
      </Heading>

      <LogosContainer>
        {IMG_DATA.map((data, i) => {
          return (
            <Logo
              loading={"eager"}
              fadeIn={false}
              key={i}
              fluid={imgData[data.key].childImageSharp.fluid}
              alt={data.title}
            />
          )
        })}
      </LogosContainer>
    </Root>
  )
}

export default AsSeenIn
