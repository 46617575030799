import React, { useRef } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { LAYOUT, FONT_FAMILIES, COLORS } from "../constants"
import BodyText from "../components/BodyText"
import iconChevronLeft from "../assets/img/ourAmbassadors/icon-chevron-left.svg"
import iconChevronRight from "../assets/img/ourAmbassadors/icon-chevron-right.svg"

const Root = styled.div`
  max-width: 908px;
  display: flex;
  padding: 50px 0 50px 0;
  margin: 0 auto;
  flex-direction: column;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding: 100px 0 50px 0;
  }
`

const Slide = styled.div`
  padding-right: 40px;
  pointer-events: none;
`

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    flex-direction: row;
  }
`

interface IImage {
  fluid: object
}

const Image = styled(Img)<IImage>`
  pointer-events: none;
  width: 100%;
  flex-shrink: 0;
  max-width: 350px;
  max-height: 350px;
  margin: 0 auto 14px auto;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-bottom: 0;
  }
`

const SliderControls = styled.div`
  display: none;
  width: 100px;
  margin: 30px auto 0 auto;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    display: flex;
  }
`
const StyledBodyText = styled(BodyText)`
  flex: 1;
  margin-bottom: 8px;

  p {
    font-size: 1.8rem;
    font-family: ${FONT_FAMILIES.RFH_REGULAR};
    text-align: center;

    @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
      text-align: left;
      font-size: 2.8rem;
    }
  }
`

const CopyContainer = styled.div`
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding-left: 44px;
  }
`

const Author = styled.p`
  font-size: 1.6rem;
  color: ${COLORS.WHITE};
  text-align: center;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    text-align: left;
    font-size: 2.2rem;
  }
`

interface IOurCommunityStories {
  rest?: object
}

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  cssEase: "linear",
  centerMode: true,
  arrows: false,
  pauseOnHover: false,
}
const IMG_DATA = [
  {
    title: "Phil",
    key: "phil",
    quote:
      "I participated in Run For Heroes and have run 5k everyday since – clocking over 200km and losing 3 stone along the way",
  },
  {
    title: "Lydia",
    key: "lydia",
    quote:
      "Run For Heroes motivated me to run. I never thought I was a runner – but to my surprise after I completed the challenge, the following day I put my running shoes back on",
  },
  {
    title: "Nicholas",
    key: "nicholas",
    quote:
      "Thank you for launching an all-inclusive charity campaign that I can get involved with.",
  },
  {
    title: "Sir Mo Farah",
    key: "sirMoFarah",
    quote:
      "Going out for a run clears your head, resets you for the day and gives you some fresh air. This challenge is so simple - asking people to run 5k, donate £5 and nominate 5 people",
  },
  {
    title: "Laura, Creator of Couch to 5k",
    key: "laura",
    quote:
      "A brilliant movement designed to get everyone moving and donating to those amazing people who looked after us during the past few months",
  },
  {
    title: "Ellie Goulding",
    key: "ellieGoulding",
    quote:
      "Thank you Run For Heroes for supporting the NHS. I’m going to nominate 5 friends and family too participate in this amazing challenge too",
  },
  {
    title: "Dr Hazel Wallace, The Food Medic",
    key: "theFoodMedic",
    quote: "Thanks for all your hard work getting people moving and donating",
  },
  {
    title: "Boris Johnson, Prime Minister",
    key: "borisJohnson",
    quote:
      "I have been inspired by the ingenuity of Run For Heroes. You’ve roused a global movement that has collectively run the entire distance to the moon and back",
  },
  {
    title: "Grace",
    key: "grace",
    quote:
      "I spread my 5k over 5 days and dressed up in different costumes - making it really fun!",
  },
]

const OurCommunityStories: React.FC<IOurCommunityStories> = ({ ...rest }) => {
  const sliderRef = useRef<Slider>(null)

  const imgData = useStaticQuery(graphql`
    query OurCommunityStoriesQuery {
      phil: file(relativePath: { eq: "ourCommunityStories/phil.png" }) {
        childImageSharp {
          fluid(maxWidth: 305, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      lydia: file(relativePath: { eq: "ourCommunityStories/lydia.png" }) {
        childImageSharp {
          fluid(maxWidth: 305, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      nicholas: file(relativePath: { eq: "ourCommunityStories/nicholas.png" }) {
        childImageSharp {
          fluid(maxWidth: 305, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sirMoFarah: file(
        relativePath: { eq: "ourCommunityStories/sir-mo-farah.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 305, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      laura: file(relativePath: { eq: "ourCommunityStories/laura.png" }) {
        childImageSharp {
          fluid(maxWidth: 305, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ellieGoulding: file(
        relativePath: { eq: "ourCommunityStories/ellie-goulding.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 305, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      theFoodMedic: file(
        relativePath: { eq: "ourCommunityStories/food-medic.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 305, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      borisJohnson: file(
        relativePath: { eq: "ourCommunityStories/boris-johnson.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 305, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      grace: file(relativePath: { eq: "ourCommunityStories/grace.png" }) {
        childImageSharp {
          fluid(maxWidth: 305, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <Slider ref={sliderRef} {...settings}>
        {IMG_DATA.map((data, i) => (
          <Slide key={i}>
            <SlideContainer>
              <Image fluid={imgData[data.key].childImageSharp.fluid} />
              <CopyContainer>
                <StyledBodyText type={"LIGHT"}>
                  <p>{data.quote}</p>
                </StyledBodyText>
                <Author>- {data.title}</Author>
              </CopyContainer>
            </SlideContainer>
          </Slide>
        ))}
      </Slider>
      <SliderControls>
        <img
          src={iconChevronLeft}
          onClick={() => sliderRef.current.slickPrev()}
        />
        <img
          src={iconChevronRight}
          onClick={() => sliderRef.current.slickNext()}
        />
      </SliderControls>
    </Root>
  )
}

export default OurCommunityStories
