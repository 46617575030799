import React from "react"
import styled from "styled-components"

import { LAYOUT } from "../constants"

const STATS_DATA = [
  {
    amount: "7,000,000",
    description: <>pounds raised</>,
  },
  {
    amount: "7,000,000",
    description: <>kilometres run</>,
  },
  {
    amount: "99",
    description: (
      <>
        years old was the
        <br /> oldest participant
      </>
    ),
  },
  {
    amount: "9",
    description: (
      <>
        months old was the
        <br /> youngest participant
        <br />
        <span>(Baby Otis crawled 5cm!)</span>
      </>
    ),
  },
  {
    amount: "20+",
    description: (
      <>
        countries got
        <br /> involved
      </>
    ),
  },
]

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  margin: 40px auto;
  flex-direction: column;
`

const VideoContainer = styled.div`
  max-width: 908px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  padding: 56.25% 0 0 0;

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    height: 550px;
    padding: 0;
  }

  iframe {
    max-width: 908px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

interface IOurStory {
  rest?: object
}

const Video: React.FC<IOurStory> = ({ ...rest }) => {
  return (
    <Root {...rest}>
      <VideoContainer>
        <iframe
          title={"Run for heroes campaign"}
          src="https://player.vimeo.com/video/411432993?color=ffffff&title=0&byline=0&portrait=0"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen={true}
        ></iframe>
      </VideoContainer>
    </Root>
  )
}

export default Video
