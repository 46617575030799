import React, { useRef } from "react"
import styled from "styled-components"

import Slider from "react-slick"
import Button from "../components/Button"
import BodyText from "../components/BodyText"
// import fiveKChallengeImage from "../assets/img/hero/5k-challenge.png"
// import fiveKChallengeImage2x from "../assets/img/hero/5k-challenge@2x.png"
import runForHeroesImage from "../assets/img/hero/run-for-heroes.png"
import runForHeroesImage2x from "../assets/img/hero/run-for-heroes@2x.png"
import runForHeroesBgImage from "../assets/img/hero/run-for-heroes-bg.png"
import runForHeroesBgImage2x from "../assets/img/hero/run-for-heroes-bg@2x.png"

import { LAYOUT, LINKS } from "../constants"
// import iconChevronLeft from "../assets/img/ourAmbassadors/icon-chevron-left.svg"
// import iconChevronRight from "../assets/img/ourAmbassadors/icon-chevron-right.svg"

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  cssEase: "linear",
  arrows: false,
  pauseOnHover: false,
}

const Root = styled.div`
  position: relative;
  background-image: url(${runForHeroesBgImage});
  background-size: cover;
  background-repeat: no-repeat;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url(${runForHeroesBgImage2x});
  }
`

// const FivekImage = styled.div`
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-image: url(${fiveKChallengeImage});
//   width: 100%;
//   background-position: center;
//   height: 200px;
//   margin-bottom: 15px;

//   @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
//     height: 486px;
//   }

//   @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//     background-image: url(${fiveKChallengeImage2x});
//   }
// `

const RfhImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${runForHeroesImage});
  width: calc(100% - 40px);
  align-self: center;
  background-position: center;
  height: 200px;
  margin-bottom: 15px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    height: 450px;
    margin-bottom: 0;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url(${runForHeroesImage2x});
  }
`

const HeroContainer = styled.div`
  max-width: 906px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding-top: 0;
    flex-direction: unset;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }
`

const SummaryDescription = styled(BodyText)`
  max-width: 568px;
  padding: 0 20px;
  margin: 0 auto 50px auto;
  p,
  a {
    font-size: 1.6rem;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding: 0;
    margin-bottom: 30px;
  }
`

const StyledButton = styled(Button)`
  margin-bottom: 15px;
  align-self: center;
  margin-right: 10px;
  margin-left: 10px;
  width: 200px;
  justify-content: center;
`

const Slide = styled.div<{ bgImage?: string }>`
  display: flex;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-position: center;
  padding: 0 20px 40px 20px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    flex-direction: row;
  }
`

// const PreviousSlide = styled.img`
//   position: absolute;
//   top: 50%;
//   left: 0;
//   z-index: 1;
// `
// const NextSlide = styled.img`
//   position: absolute;
//   top: 50%;
//   right: 0;
//   z-index: 1;
// `

interface IHero {
  rest?: object
}

const Hero: React.FC<IHero> = ({ ...rest }) => {
  // const sliderRef = useRef<Slider>(null)

  return (
    <Root {...rest}>
      <HeroContainer>
        <RfhImage />

        <SummaryDescription type={"LIGHT"} alignment={"CENTER"}>
          <p>
            We’re on a mission to get as many people active as possible while
            raising vital funds for charity.
          </p>
          <p>
            Our goal is to make fundraisers more inclusive, more accessible and
            more sustainable, shaping a brighter future for charity events.
          </p>
        </SummaryDescription>
        <ButtonContainer>
          <StyledButton
            color={"TRANSPARENT"}
            href={LINKS.DONATE}
            target={"_blank"}
            rel={"noopener"}
          >
            Donate
          </StyledButton>
          <StyledButton to={"/about-us/our-charity/"}>
            Get involved
          </StyledButton>
        </ButtonContainer>
      </HeroContainer>
      {/* <PreviousSlide
        src={iconChevronLeft}
        onClick={() => sliderRef.current.slickPrev()}
      />
      <NextSlide
        src={iconChevronRight}
        onClick={() => sliderRef.current.slickNext()}
      /> */}
      {/* <Slider ref={sliderRef} {...settings}>
        <Slide bgImage={runForHeroesBgImage}>
          <HeroContainer>
            <RfhImage />

            <SummaryDescription type={"LIGHT"} alignment={"CENTER"}>
              <p>
                We’re on a mission to get as many people active as possible
                while raising vital funds for charity.
              </p>
              <p>
                Our goal is to make fundraisers more inclusive, more accessible
                and more sustainable, shaping a brighter future for charity
                events.
              </p>
            </SummaryDescription>
            <ButtonContainer>
              <StyledButton to={"/sign-up"}>Learn more</StyledButton>
              <StyledButton to={"/sign-up"}>Get involved</StyledButton>
            </ButtonContainer>
          </HeroContainer>
        </Slide>
        <Slide>
          <HeroContainer>
            <FivekImage />
            <StyledButton to={"/sign-up"}>Get involved</StyledButton>
            <SummaryDescription type={"LIGHT"} alignment={"CENTER"}>
              <p>
                Already participated? <a href="#">Donate now</a>
              </p>
            </SummaryDescription>
          </HeroContainer>
        </Slide>
      </Slider> */}
    </Root>
  )
}

export default Hero
