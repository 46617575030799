import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import BodyText from "../components/BodyText"
import { H2 } from "../components/Heading"
import { LAYOUT, LINKS } from "../constants"
import helloFreshLogo from "../assets/img/ourPartners/hello-fresh.png"
import zipcarLogo from "../assets/img/ourPartners/zipcar.png"
import justGivingLogo from "../assets/img/ourPartners/just-giving.png"
import lululemonLogo from "../assets/img/ourPartners/lululemon.png"
import classpassLogo from "../assets/img/ourPartners/classpass.svg"

const IMG_DATA = [
  { title: "Zipcar", src: zipcarLogo, url: LINKS.ZIP_CAR },
  { title: "Just giving", src: justGivingLogo, url: LINKS.JUST_GIVING },
  { title: "Hello fresh", src: helloFreshLogo, url: LINKS.HELLO_FRESH },
  // { title: "Lululemon", src: lululemonLogo },
  // { title: "Classpass", src: classpassLogo, url: LINKS.CLASSPASS },
]

const Root = styled.div`
  padding: 40px 0 60px 0;
`

const Heading = styled(H2)`
  margin-bottom: 40px;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const Logo = styled.img`
  align-self: center;
`

const StyledLink = styled.a`
  max-width: 202px;
  margin-bottom: 20px;
  width: 45%;
  align-self: center;

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    margin-bottom: 40px;
    width: 202px;
  }
`

interface IOurPartners {
  rest?: object
}

const Hero: React.FC<IOurPartners> = ({ ...rest }) => {
  return (
    <Root {...rest}>
      <Heading color={"WHITE"} alignment={"CENTER"}>
        Our Partners{" "}
      </Heading>
      <LogoContainer>
        {IMG_DATA.map((data, i) => (
          <StyledLink
            href={data.url}
            target={"_blank"}
            key={i}
            rel={"noopener"}
          >
            <Logo key={data.title} src={data.src} alt={data.title} />
          </StyledLink>
        ))}
      </LogoContainer>
      <BodyText type={"LIGHT"} alignment={"CENTER"}>
        <Link to={"/our-partners/corporate"}>See all of our partners</Link>
      </BodyText>
    </Root>
  )
}

export default Hero
